<template>
  <div class="header-div">
    <div class="header-div__title">
      <slot name="title"></slot>
    </div>
    <div class="header-div__content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderDiv"
}
</script>

<style scoped>
.header-div {
  display: flex;
  width: 100%;
  align-items: center;

}

.header-div__content {
  padding: 10px 20px;
}

.header-div__title::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 2em;
  background: #eaeaea;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.header-div__title {
  position: relative;
  padding: 10px 20px 10px 0;
}
</style>
